.slider-img {
    padding: 3px;
    text-align: center;
    width: 100%;
    height: 50px;
    object-fit: contain;

}

.container {
    width: 100%;
    margin: auto;
    overflow: hidden; 
}

.container .slick-slide {
    padding: 10px; 
}
