.list-group-item {
    border: none !important; /* Menghilangkan border */
    background-color: transparent !important; /* Mengatur background menjadi transparan */
    border-radius: 0 !important; /* Menghilangkan border radius jika ada */
}

.active-nav-item.active {
    background-color: rgba(255, 68, 68, 0.644) !important;
    color: white !important;
    border-radius: 20px;
}
.no-border-on-hover {
    border: none !important; /* Ensures no border is present initially */
}

.no-border-on-hover:hover,
.no-border-on-hover:focus {
    border: none !important; /* Removes border on hover and focus */
    outline: none !important; /* Removes focus outline if any */
    box-shadow: none !important; /* Removes box-shadow if any */
}
