.bgc {
    position: relative;
    padding: 20px;
}

.bgc::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/bg2.jpg');
    background-size: cover;
    background-position: center;
    opacity: 0.3;
    z-index: 1;
}

.bgc .container {
    position: relative;
    z-index: 2; 
}
.custom-margins-padding {
    margin-top: 1.5rem;
    margin-bottom: 3rem; 
    padding-top: 3rem; 
    padding-bottom: 3rem; 
}
.custom-gap {
    gap: 3rem;
}


.cctv-title {
    font-size: 43px;
    font-weight: bold;
}
.dis-none{
    display: none;
}

.cctv-subtitle {
    font-size: 12px;
    margin-top: -10px;
    opacity: 0.7;
    letter-spacing: 1px;
    word-spacing: 2px;
    line-height: 1.5;
}

.cctv-description {
    text-align: justify;
    /* text-indent: 30px; */
    font-size: 18px;
}

.check-icon {
    width: 30px;
    margin-right: 10px;
}

.konsultasi-button {
    font-family: 'Poppins, sans-serif';
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    height: 40px;
}

.title-tawaran{
    margin-top: 20px;
}
.title-tawaran2{
    margin-top: 20px;
    font-size: 20px;
}
.title-tawaran3{
    margin-top: 20px;
}
.btn-tawaran{
    margin-top: 10px;
}

.custom-flex-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-size: cover;
    height: 80vh;
  }
.ct2{
    text-align: end;
}
.title-header-content{
    font-size: 19px;
}
.title-header-content2{
    font-size: 70px;
    margin-top: -25px;
}
.img-mobile-show{
    display: none;
}

@media (max-width: 769px) {
    .cctv-title {
        font-size: 32px;
        text-align: center;
        margin-top: -30px;
    }
    .cctv-subtitle {
        font-size: 10px;
        margin-top: 1px;
        word-spacing: 1px;
        letter-spacing: 0px;
        text-align: center;
    }

    .cctv-description {
        font-size: 13px;
        margin-top: 5px !important;
    }

    .check-icon {
        width: 25px;
    }
    .check-des{
        font-size: 13px;
    }
    .container-btn-konsul{
        text-align: center;
        margin-bottom: 15px;
    }

    .konsultasi-button {
        font-size: 12px;
    }
    .icc{
        margin-top: -10px !important;
        margin-bottom: 50px !important;
    }
    .custom-flex-container {
        display: flex;
        justify-content: center; 
        align-items: center; 
        background-size: cover;
        height: 65vh;
        background-image: url('../img/bcbg.jpg') !important;
    }

    .title-header-content {
        font-size: 27px;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-style: normal;
    }

    .title-header-content2 {
        /* font-size: 40px !important;
        margin-top: -25px; */
        display: none;
    }
    .ct2{
        text-align: start !important;
    }
    /* .img-mobile-none{
        display: none;
    }
    .img-mobile-show{
        display: block;
        margin-bottom: 20px;
    } */
    .tititle-ai{
        margin-top: -200px !important;
    }
    .custom-margins-padding {
        margin-top: 1.5rem; /* Mengganti mt-4 */
        margin-bottom: 1rem; /* Mengganti mb-5 */
        padding-top: 3rem; /* Mengganti pt-5 */
        padding-bottom: 2.5rem; /* Mengganti pb-5 */
    }
    .custom-gap {
        gap: 10px; 
    }
    .haruskami{
        text-align: center;
        font-size: 13px;
    }
    .haruskami1{
        font-size: 18px !important;
    }
    .haruskami-t{
        font-size: 18px;
    }
    .slug-t{
        font-size: 10px;
    }
    .tt-kami{
        font-size: 12px;
    }
    .title-tawaran2{
        font-size: 13px;
    }
    .btn-tawaran{
        font-size: 13px !important;
        margin-top: 1px;
    }
    .c-tw{
        margin-top: -20px;
    }
    .dis-none{
        display: block;
    }
    .tt2{
        margin-top: -25px !important;
    }
}

/* Responsive for screens with width <= 425px */
@media (max-width: 426px) {
    .cctv-title {
        margin-top: -35px;
        font-size: 32px;
        text-align: center;
    }
    .cctv-subtitle {
        font-size: 10px;
        margin-top: -1px;
        word-spacing: 1px;
        letter-spacing: 0px;
        text-align: center;
    }

    .cctv-description {
        margin-top: 5px !important;
        font-size: 13px;
    }

    .check-icon {
        width: 25px;
    }
    .check-des{
        font-size: 13px;
    }
    .container-btn-konsul{
        text-align: center;
        margin-bottom: 15px;
    }

    .konsultasi-button {
        font-size: 12px;
    }
    .icc{
        margin-top: -0px !important;
        margin-bottom: 50px !important;
    }
    .custom-flex-container {
        display: flex;
        justify-content: center; /* Mengatur horizontal centering */
        align-items: center; /* Mengatur vertical centering */
        background-size: cover;
        height: 65vh;
        background-image: url('../img/bcbg.jpg') !important;
    }

    .title-header-content {
        margin-top: 50px; 
        font-size: 38px;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-style: normal;
    }

    .title-header-content2 {
        /* font-size: 40px !important;
        margin-top: -25px; */
        display: none;
    }
    .ct2{
        text-align: start !important;
    }
    .img-mobile-none{
        display: none;
    }
    .img-mobile-show{
        display: block;
        margin-bottom: 20px;
    }
    .tititle-ai{
        margin-top: -200px !important;
    }
    .custom-margins-padding {
        margin-top: 1.5rem; /* Mengganti mt-4 */
        margin-bottom: 1rem; /* Mengganti mb-5 */
        padding-top: 3rem; /* Mengganti pt-5 */
        padding-bottom: 2.5rem; /* Mengganti pb-5 */
    }
    .custom-gap {
        gap: 0px; /* Mengganti gap-5 dari Bootstrap */
    }
    .haruskami{
        text-align: center;
        font-size: 13px;
    }
    .haruskami1{
        font-size: 18px !important;
    }
    .haruskami-t{
        font-size: 18px;
    }
    .slug-t{
        font-size: 10px;
    }
    .tt-kami{
        font-size: 12px;
    }
    .title-tawaran2{
        font-size: 13px;
    }
    .btn-tawaran{
        display:flex ;
        width: 200px;
        height: 40px;
        font-size: 14px !important;
        justify-content: center;
        align-items: center;
    }
    .btn-tawaran1{
        display:flex ;
        height: 40px;
        font-size: 14px !important;
        justify-content: center;
        align-items: center;
    }
    .c-tw{
        margin-top: -20px;
    }
    .dis-none{
        display: block;
    }
    .tt2{
        margin-top: -25px !important;
    }
}

/* Responsive for screens with width <= 375px */
@media (max-width: 376px) {
    .cctv-title {
        font-size: 32px;
        text-align: center;
    }
    .cctv-subtitle {
        font-size: 10px;
        margin-top: 1px;
        word-spacing: 1px;
        letter-spacing: 0px;
        text-align: center;
    }

    .cctv-description {
        margin-top: -2px !important;
        font-size: 13px;
    }

    .check-icon {
        width: 25px;
    }
    .check-des{
        font-size: 12px;
    }
    .container-btn-konsul{
        text-align: center;
        margin-bottom: 15px;
    }

    .konsultasi-button {
        font-size: 12px;
    }
    .icc{
        margin-top: -10px !important;
        margin-bottom: 20px !important;
    }
    .custom-flex-container {
        display: flex;
        justify-content: center; /* Mengatur horizontal centering */
        align-items: center; /* Mengatur vertical centering */
        background-size: cover;
        height: 90vh;
        background-image: url('../img/bcbg.jpg') !important;
    }

    .title-header-content {
        margin-top: 65px;
        font-size: 35px;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-style: normal;
    }

    .title-header-content2 {
        /* font-size: 40px !important;
        margin-top: -25px; */
        display: none;
    }
    .ct2{
        text-align: start !important;
    }
    .img-mobile-none{
        display: none;
    }
    .img-mobile-show{
        display: block;
        margin-bottom: 20px;
    }
    .tititle-ai{
        margin-top: -200px !important;
    }
    .custom-margins-padding {
        margin-top: 1.5rem; /* Mengganti mt-4 */
        margin-bottom: 1rem; /* Mengganti mb-5 */
        padding-top: 3rem; /* Mengganti pt-5 */
        padding-bottom: 2.5rem; /* Mengganti pb-5 */
    }
    .custom-gap {
        gap: 0px; /* Mengganti gap-5 dari Bootstrap */
    }
    .haruskami{
        text-align: center;
        font-size: 13px;
    }
    .haruskami1{
        font-size: 18px !important;
    }
    .haruskami-t{
        font-size: 18px;
    }
    .slug-t{
        font-size: 10px;
    }
    .tt-kami{
        font-size: 12px;
    }
    .title-tawaran2{
        font-size: 13px;
    }
    .btn-tawaran{
        display:flex ;
        width: 200px;
        height: 40px;
        font-size: 14px !important;
        justify-content: center;
        align-items: center;
    }
    .btn-tawaran1{
        display:flex ;
        height: 40px;
        font-size: 14px !important;
        justify-content: center;
        align-items: center;
    }
    .c-tw{
        margin-top: -20px;
    }
    .dis-none{
        display: block;
    }
    .tt2{
        margin-top: -25px !important;
    }
}

/* Responsive for screens with width <= 320px */
@media (max-width: 320px) {
    .cctv-title {
        font-size: 28px;
        text-align: center;
    }
    .cctv-subtitle {
        font-size: 9px;
        margin-top: -1px;
        word-spacing: 1px;
        letter-spacing: 0px;
        text-align: center;
    }

    .cctv-description {
        margin-top: 0px !important;
        font-size: 13px;
    }

    .check-icon {
        width: 18px;
    }
    .check-des{
        font-size: 11px;
    }
    .container-btn-konsul{
        text-align: center;
        margin-bottom: 15px;
    }
    .konsultasi-button {
        font-size: 12px;
    }
    .icc{
        margin-top: -10px !important;
        margin-bottom: 20px !important;
    }
    .custom-flex-container {
        display: flex;
        justify-content: center; /* Mengatur horizontal centering */
        align-items: center; /* Mengatur vertical centering */
        background-size: cover;
        height: 90vh;
        background-image: url('../img/bcbg.jpg') !important;
    }

    .title-header-content {
        font-size: 27px;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-style: normal;
    }

    .title-header-content2 {
        /* font-size: 40px !important;
        margin-top: -25px; */
        display: none;
    }
    .ct2{
        text-align: start !important;
    }
    .img-mobile-none{
        display: none;
    }
    .img-mobile-show{
        display: block;
        margin-bottom: 20px;
    }
    .tititle-ai{
        margin-top: -200px !important;
    }
    .custom-margins-padding {
        margin-top: 1.5rem; /* Mengganti mt-4 */
        margin-bottom: 1rem; /* Mengganti mb-5 */
        padding-top: 3rem; /* Mengganti pt-5 */
        padding-bottom: 2.5rem; /* Mengganti pb-5 */
    }
    .custom-gap {
        gap: 0px; /* Mengganti gap-5 dari Bootstrap */
    }
    .haruskami{
        text-align: center;
        font-size: 13px;
    }
    .haruskami1{
        font-size: 18px !important;
    }
    .haruskami-t{
        font-size: 18px;
    }
    .slug-t{
        font-size: 10px;
    }
    .tt-kami{
        font-size: 12px;
    }
    .title-tawaran2{
        font-size: 13px;
    }
    .btn-tawaran{
        display:flex ;
        width: 200px;
        height: 40px;
        font-size: 14px !important;
        justify-content: center;
        align-items: center;
    }
    .btn-tawaran1{
        display:flex ;
        height: 40px;
        font-size: 14px !important;
        justify-content: center;
        align-items: center;
    }
    .c-tw{
        margin-top: -20px;
    }
    .dis-none{
        display: block;
    }
    .tt2{
        margin-top: -25px !important;
    }
}

.effectCard {
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    background-color: rgba(255, 255, 255);
}

.effectCard:hover {
    transform: scale(1.05);
    background-color: #ff4d4d; 
    cursor: pointer; 
    color: white;
    box-shadow: none !important;
}
.effectCard:hover svg {
    fill: white;
}

.bg-tunggulagi {
    background-size: cover;
    background-position: center;
    padding: 30px;
    height: 53vh;
    background-image: url('../img/bg2211.png');
}

