/* CSS for Partner logos */
.partner-logo {
    max-width: 100%; /* Use full column width */
    height: auto; /* Maintain aspect ratio */
  }
  
  @media (max-width: 576px) { /* Adjust for smaller devices */
    .partner-logo {
      max-height: 40px; /* Smaller height on smaller screens */
    }
  }
  
  @media (min-width: 577px) {
    .partner-logo {
      max-height: 60px; /* Larger height on larger screens */
    }
  }
  