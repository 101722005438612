@media only screen and (max-width: 425px) {
    .footer-menu {
        display: none;
    }
    .sizefont{
        font-size: 13px;
    }
}
.footer-background {
    position: relative;
    background-color: rgba(227, 24, 9, 0.8);
}

.footer-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://wallpaperaccess.com/full/1995839.jpg');
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    z-index: 1;
}

.footer-content {
    position: relative;
    z-index: 2;
}
