.image-background {
    background-image: url('../img/bg-nav.jpg'); /* Update path accordingly */
    background-size: cover;
    background-position: center;
    text-align: center;
  }
  
  .image-backgroundfoot {
    background-color: red;
    background-position: center;
    height: 370px;
  }
  
  .image-backgroundfooter {
    background-image: url('../img/bg-nav.jpg'); 
    background-size: cover;
    background-position: center;
  }
  
  .breadcrumb {
    padding-bottom: 200px;
    background: none;
    padding: 0;
    justify-content: center; 
  }
  
  .breadcrumb-item + .breadcrumb-item::before {
    color: white;
  }
  
  .breadcrumb-item a {
    color: lightblue;
  }
  
  .spacing-1{
    letter-spacing: 1px;
  }
  
  .dropdown-item.activefill {
    background-color: #dc3545;
    color: white;
  }
  
  .slug {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  @media (min-width: 200px) {
    .t-desc, .title, .ctr-urutkan, .imgg, .textnav, .jj1 {
      /* specific styles for each class */
    }
  }
  
  @media (min-width: 319px) {
    .t-desc, .title, .ctr-urutkan, .imgg, .mysosmed, .mynav {
      /* specific styles for each class */
    }
  }
  
  @media (min-width: 374px) {
    .t-desc, .title, .ctr-urutkan, .imgg, .mysosmed, .mynav {
      /* specific styles for each class */
    }
  }
  
  @media (min-width: 425px) {
    .t-desc, .title, .ctr-urutkan, .imgg, .mysosmed, .mynav, .textnav {
      /* specific styles for each class */
    }
  }
  
  @media (min-width: 767px) {
    .t-desc, .title, .imgg {
      /* specific styles for each class */
    }
  }
  
  @media (min-width: 991px) {
    .t-desc, .title {
      /* specific styles for each class */
    }
  }
  
  @media (min-width: 1023px) {
    .t-desc, .title, .mysosmed, .mynav {
      /* specific styles for each class */
    }
  }
  
  @media (min-width: 1200px) {
    .t-desc, .title, .mysosmed, .mynav {
      /* specific styles for each class */
    }
  }
  
  .btn-custom {
    background-color: white;
    color: red;
    border: 1px solid red;
    border-radius: 25px;
  }
  
  .btn-custom:hover {
    background-color: red;
    color: white;
  }
  
  .btn-custom .whatsapp-icon {
    margin-right: 8px;
  }
  
  #loading-modal {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Background semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-modal-content {
    text-align: center;
    color: white;
  }
  
  .loading-animation {
    margin-top: 20px;
    /* Add CSS loading animation here */
  }
  
  .alert {
    display: block !important;
    z-index: 9999 !important;
    transition: opacity 0.5s ease-out;
    width: 20%;
    font-size: 18px;
    font-weight: 500;
  }
  